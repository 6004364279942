import { GetStaticProps } from 'next';
import { TPropsAttractionsPage } from '@/entities/Attractions/domain/Attractions.domain';
import { getFeaturedSectionTitle } from '@/shared/FeaturedActivities/ui/featuredActivitiesPreview/utils';
import { fetchLandmarksByCityId } from '@/screens/LandmarkMainPage/service/fetchLandmarksByCityId';
import { getRecommends } from '@/shared/FeaturedActivities/service/ApiFeaturedActivities';
import {
    getCardImagesWithPlaceholders,
    getPlaceholders,
} from '@/common/service/api/Activity/Activity';
import { getActivityDateless } from '@/entities/Attractions/service/ApiAttractionsPage';
import { getDestination, CreateAttractionsPageTitle, createAttractionsPageMeta, createFaqSlug } from '@/entities/Attractions/service/Creators';
import { getFAQ } from '@/shared/FAQ/service/ApiFaq';
import { getCategoriesFeatured } from '@/shared/TopAttractions/service/ApiTopAttractions';
import AttractionsPage from 'pages/attractions/[destination]';
import destinationsList from '..//../../../../scripts/cache/destinationsList.json';
import { getRedirectsPath } from '@/common/service/api/Redirects/getRedirectsPath';

export async function getStaticPaths() {
    const paths = destinationsList.map((value) => ({
        params: {
            destination: value.slug.toLowerCase(),
            state: value.state.code.toLowerCase(),
        },
    }));

    return {
        paths,
        fallback: 'blocking',
    };
}

type PageStaticProps = {
    destination: string;
    state: string;
};

export const getStaticProps: GetStaticProps<TPropsAttractionsPage, PageStaticProps> = async ({
    params,
}) => {
    try {
        if (!params) {
            throw new Error(`PAGE THINGS TO DO /DESTINATION: BAD PARAMS: ${params}`);
        }

        const currentDestination = getDestination(params.destination || '');
        const currentPath = `/destination/${params.state}/${params.destination}/things-to-do/`;

        if (!currentDestination) {
            const redirectPath = await getRedirectsPath(currentPath);

            return {
                revalidate: Number(process.env.CACHE_ENV),
                redirect:{
                    destination: redirectPath.toLowerCase(),
                    permanent: true,
                },
            };
        }

        const rightPath = `/destination/${currentDestination.state.code}/${currentDestination.slug}/things-to-do/`.toLowerCase();

        if(currentPath !== rightPath){
            return {
                revalidate: Number(process.env.CACHE_ENV),
                redirect:{
                    destination: rightPath,
                    permanent: true,
                },
            };
        };

        const title = CreateAttractionsPageTitle(currentDestination);
        const featuredActivitiesTitle = getFeaturedSectionTitle(currentDestination.name);
        const meta = createAttractionsPageMeta(currentDestination);
        const faqSlug = createFaqSlug(currentDestination);

        const breadcrumbs = [
            {
                url: '/destination/',
                title: 'Destination',
            },
            {
                url: rightPath.replace('things-to-do/', ''),
                title: currentDestination.name,
            },
            {
                title: 'Things to do',
                url: rightPath,
                unlink: true,
            },
        ];

        const [fullList, featuredCategories, faq, landmarks, recommends] = await Promise.all([
            getActivityDateless(currentDestination.id),
            getCategoriesFeatured(currentDestination.id),
            getFAQ(faqSlug),
            fetchLandmarksByCityId(currentDestination.id),
            getRecommends({
                destination_id: currentDestination.id,
            }),
        ]);

        const ids = fullList.items.map(({ id }) => id);

        const [images, placeholders] = await Promise.all([
            getCardImagesWithPlaceholders(ids),
            getPlaceholders(ids, 'activity'),
        ]);

        fullList.items.forEach((trip) => {
            trip.price = 0;
            trip.images = images[trip.id] || [];
            trip.placeholder = placeholders[trip.id] || '';
        });

        return {
            revalidate: Number(process.env.CACHE_ENV),
            props: {
                meta,
                breadcrumbs,
                featuredCategories,
                title,
                featuredActivitiesTitle,
                fullList,
                faq,
                currentDestination,
                isEmpty: !fullList.total,
                landmarks,
                noRecommends: !recommends.length,
                availableAttributes: [],
                placeholders,
            },
        };
    } catch (e) {
        throw new Error((e as Error).message);
    }
};

export default AttractionsPage;
